import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "pt-3 w-100 h-100 content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_DiseaseItem = _resolveComponent("DiseaseItem")!
  const _component_Empty = _resolveComponent("Empty")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Loading, { loading: "loading" })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.diseaseList?.length)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.diseaseList, (item) => {
            return (_openBlock(), _createBlock(_component_DiseaseItem, {
              key: item.id,
              diseaseSituation: item
            }, null, 8, ["diseaseSituation"]))
          }), 128))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (!_ctx.loading)
              ? (_openBlock(), _createBlock(_component_Empty, {
                  key: 0,
                  message: "Không có dữ liệu!"
                }))
              : _createCommentVNode("", true)
          ], 64))
    ])
  ], 64))
}