
import { defineComponent, ref, watchEffect } from "vue";
import { DISEASE_SITUATION_FRAGMENT } from "@/graphql";
import jwtDecode from "jwt-decode";
import { useRoute, useGetAll } from "@/helpers";
import { get, cloneDeep } from "lodash";
import DiseaseItem from "./components/disease-item.vue";
import Loading from "@/components/loading/index.vue";
import Empty from "@/components/empty/index.vue";

export default defineComponent({
  components: { DiseaseItem, Loading, Empty },
  setup() {
    const { query, push } = useRoute();
    const token = localStorage.getItem("x-token");
    const res = jwtDecode(String(token));

    const showDiseaseSituations = get(res, "showDiseaseSituations", "");
    if (!showDiseaseSituations) push("./no-data-result");

    const diseaseList = ref<any[]>();
    const { loading, data } = useGetAll<any>(
      "DiseaseSituation",
      DISEASE_SITUATION_FRAGMENT,
      {
        limit: 1000,
        order: { createdAt: -1 }
      }
    );

    watchEffect(() => {
      if (data.value) {
        diseaseList.value = data.value;
      }
    });
    return { loading, diseaseList };
  }
});
